import React from "react";
import Layout from "~/components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { graphql } from "gatsby";
import FAQSection from "~/components/FAQSection";

const Faqs = ({ data }) => {
	const faqs = data.strapiFrequently.frequent;

	return (
		<Layout>
			<GatsbySeo
				title="Frequently Asked Questions"
				description="Explore our Frequently Asked Questions (FAQ) page to find quick, comprehensive answers to your most common inquiries."
			/>
			<div className="my-20">
				<h1 className="font-bold">Frequently Asked Questions</h1>
				<p className="font-light text-xl text-primary max-w-3xl mb-14">
					Find quick answers to common questions about booking, travel tips,
					policies, and what to expect on your adventure. Your journey starts here!
				</p>
				<FAQSection faqs={faqs} />
				{/* <ReactMarkdown
					className="prose prose-sm sm:prose"
					children={terms.description}
				/> */}
			</div>
		</Layout>
	);
};

export const query = graphql`
	query FaqsQuery {
		strapiFrequently {
			frequent {
				question
				answer
			}
		}
	}
`;

export default Faqs;
